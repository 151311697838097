import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../layouts/Layout';
import Seo from '../components/Seo';
import { rendererOptions } from '../utils';

const Page = ({ data }) => {
  const { title, body, slug, locale } = data.contentfulPage;

  return (
    <Layout showHeader>
      <Seo title={title} path={`/${slug}`} />
      <article className="px-4 sm:px-6 lg:px-16 mx-auto max-w-5xl prose">
        <h1>{title}</h1>
        <div>
          {documentToReactComponents(
            JSON.parse(body.raw),
            rendererOptions({ locale })
          )}
        </div>
      </article>
    </Layout>
  );
};

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      body {
        raw
      }
      locale: node_locale
    }
  }
`;

export default Page;
